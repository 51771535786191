import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Нээлттэй хэлцэлүүдийг харах, хэлцэл хийх",
  "description": null,
  "author": "OTC help",
  "category": "my-deals",
  "date": "2022-04-08T00:00:00.000Z",
  "featured": true,
  "tags": [],
  "order": 1
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Нээлттэй хэлцэлүүдийг харах, хэлцэл хийх`}</h2>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Үндсэн цэснээс `}<strong parentName="p">{`[My Deals]`}</strong>{` хэсгийг сонгон `}<strong parentName="p">{`[Ongoing Deals]`}</strong>{` хэсгээс оролцогч өөрийн хамаарал бүхий нээлттэй хэлцэлүүдийн жагсаалтыг болон төлөвийг харах боломжтой`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`[Creat RFQ]`}</strong>{` болон `}<strong parentName="p">{`[Chat]`}</strong>{` хэсгээр илгээсэн болон хүлээн авсан захиалгын мэдээлэл энэ хэсэгт харагдана.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`[Ongoing Deals]`}</strong>{` жагсаалт дахь хэлцэлүүдийг `}<strong parentName="p">{`[Decline]`}</strong>{` буюу татгалзах, `}<strong parentName="p">{`[Counteroffer]`}</strong>{` буюу хэлцлийн нөхцөлийг өөрчлөн санал тавих, `}<strong parentName="p">{`[Approve]`}</strong>{` буюу зөвшөөрөх гэсэн үйлдлүүдээс сонгож хариу өгнө`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Ирсэн саналыг татгалзах бол `}<strong parentName="p">{`[Decline]`}</strong>{` товчийг дарснаар тухайн санал `}<strong parentName="p">{`[Ongoing Deals]`}</strong>{` хэсгээс устаж санал илгээсэн талд мэдэгдэл очно.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Ирсэн саналын нөхцөлийг өөрчлөн санал тавих бол `}<strong parentName="p">{`[Counteroffer]`}</strong>{` товчийг дарж хэлцлийн саналын `}<strong parentName="p">{`[Price]`}</strong>{` үнэ, `}<strong parentName="p">{`[Quantity]`}</strong>{` тоо хэмжээ, `}<strong parentName="p">{`[Trade date option]`}</strong>{` төлбөр гүйцэтгэн захиалга биелүүлэх хугацааны сонголт буюу T+0 тухайн өдөртөө, T+1 буюу дараа өдөр гэсэн сонголтоос сонгож, `}<strong parentName="p">{`[Message]`}</strong>{` тайлбар хэсэгт шаардлагатай бусад мэдээллийг оруулан `}<strong parentName="p">{`[Create]`}</strong>{` товч дарж саналыг эсрэг талд илгээнэ`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Ирсэн саналыг хүлээн авах бол `}<strong parentName="p">{`[Approve]`}</strong>{` товчийг дарж шаардлагатай мэдээллийг оруулна:`}</p>
        <ul parentName="li">
          <li parentName="ul"><strong parentName="li">{`[Client ID]`}</strong>{` хэсэгт хүлээн авч буй харилцагчийн бүртгэлийн дугаарыг`}</li>
          <li parentName="ul"><strong parentName="li">{`[Broker Fee]`}</strong>{` хэсэгт брокерын төлбөрийн хэлбэр (тогтмол дүн/хувь) болон дүнг,`}</li>
          <li parentName="ul"><strong parentName="li">{`[Custodian]`}</strong>{` хэсэгт тухайн үнэт цаасны бүртгэл, төлбөр тооцоо болон хадгалалт хийгдэж буй кастодианыг жагсаалтаас сонгоно`}</li>
          <li parentName="ul"><strong parentName="li">{`[Payment type]`}</strong>{` хэсэгт үнэт цаасны төлбөр тооцоонийй хэлбэрийг жагсаалтаас сонгоно`}</li>
          <li parentName="ul"><strong parentName="li">{`[Fee info]`}</strong>{` - хэсэгт тухайн хэлцлийн төлбөр, хураамжийг тооцон хураангуйлан харуулах бол `}<strong parentName="li">{`[Amount info]`}</strong>{` хэсгээс хэлцлийн нийт хэмжээ болон дүнг харах боломжтой`}</li>
        </ul>
      </li>
    </ol>
    <p>{`Дээрх хэлцлийн мэдээллээ нягталж шалган `}<strong parentName="p">{`[Submit]`}</strong>{` товчийг дарж хэлцэл хийх хүсэлтийг илгээнэ.`}</p>
    <p>{`Илгээсэн хэлцэл хийх хүсэлтийн мэдээллийг бүхэлд нь арилгах бол `}<strong parentName="p">{`[Reset]`}</strong>{` товчийг дарж шинээр оруулна.`}</p>
    <ol {...{
      "start": 7
    }}>
      <li parentName="ol">{`Амжилттай илгээсэн хэлцлийн хүсэлтийг Харилцагч тал мөн ижил `}<strong parentName="li">{`[Approve]`}</strong>{` хийснээр хэлцэл нь `}<strong parentName="li">{`[My Deals]`}{`-`}{`[Settlements]`}</strong>{` хэсэгт шилжинэ.`}</li>
    </ol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      